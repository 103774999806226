import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import "./_DropdownMenu.scss";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
const LogOutMenu = () => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      logout();
      dispatch({ type: "LOGOUT" });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="logout-container">
      <NavLink
        to={"/settings/user-settings"}
        style={{ textDecoration: "none", color: "#000000" }}
      >
        <MenuItem className="settings-wrapper">
          <SettingsIcon />
          <span> User Settings </span>
        </MenuItem>
      </NavLink>
      <Divider />

      <MenuItem
        className=" changed-color settings-wrapper "
        onClick={handleLogout}
      >
        <LogoutIcon />
        <span> LogOut </span>
      </MenuItem>
    </div>
  );
};

export default LogOutMenu;
